<script>
import { computed, ref } from 'vue';
import { useListingStore } from '~/stores/account/listings.store';

export default {
  props: {
    feature: {
      type: Object,
      required: true
    }
  },  

  async setup(props) {
    const listingStore = useListingStore()
    
    const feature = props.feature
    const updateValue = (event) => {
      let value = event.target.value
      let listing_field = feature.listing_field

      // temp solution
      if (['title', 'description'].includes(feature.listing_field)) {
        value = {en: `${value}`}
      };

      listingStore.updateSelectedForm(listing_field, value)
    };

    const state = computed(() => listingStore.selectedFromGetter())

    return { updateValue, feature, state };
  },
};
</script>

<template>
  <div class="relative w-full">
    <div>
      <h3 class="text-lg font-medium text-indigo-900 leading-normal text-left">
        {{ feature.feature_name }} 
        <span v-if="feature.is_required">*</span>
      </h3>
      <div class="mt-2">
        <input 
          v-if="['text', 'number', 'url'].includes(feature.input_type)"
          type="text" 
          :placeholder="feature.feature_name"
          class="block w-full ring-1 ring-gray-300 ring-inset shadow-sm text-gray-900 text-sm p-3 rounded-md"
          @input="updateValue"
        >
        <textarea 
          v-else-if="feature.input_type == 'textarea'"
          name="" 
          id="" 
          rows="7"
          class="block w-full ring-1 ring-gray-300 ring-inset shadow-sm text-gray-900 text-sm p-3 rounded-md"
          :placeholder="feature.feature_name"
          @input="updateValue"
        ></textarea>
      </div>
    </div>
  </div>
</template>