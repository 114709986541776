<script>
import { computed } from 'vue';
import { useListingStore } from '~/stores/account/listings.store';

export default {
  props: {
    feature: {
      type: Object,
      required: true
    }
  },  

  async setup(props) {
    const listingStore = useListingStore()
    
    const feature = props.feature
    const updateValue = (id) => {
      if (feature.listing_field) {
        listingStore.updateSelectedForm(feature.listing_field, id)
      } else {
        listingStore.updateFeature(feature.feature_id, id)
      }
    };

    const state = computed(() => listingStore.selectedFromGetter())

    const isSelected = (source) => {
      if (feature.listing_field) {
        return state.value?.[feature.listing_field] == source.id
      } else {
        return state.value?.features?.[feature.feature_id] == source.id
      };
    }

    return { updateValue, isSelected, feature, state };
  },
};
</script>

<template>
  <div class="relative w-full">
    <div>
        <h3 class="text-lg font-medium text-indigo-900 leading-normal text-left">
          {{ feature.feature_name }} 
          <span v-if="feature.is_required">*</span>
        </h3>
        <div class="w-full flex items-center gap-2 my-3 snap-x overflow-x-auto">
            <div 
              v-for="(source) in feature.data_source" 
              :key="source.id" 
              @click="updateValue(source.id)" 
              class="relative snap-start shrink-0"
            >
                <a 
                  class=" rounded-xl p-3 block"
                  :class="isSelected(source) ? 'bg-indigo-900 text-white' : 'bg-[#d4dae4]'"
                >
                    <h4 class="px-2">{{ source.name }}</h4>
                </a>
            </div>
        </div>
    </div>
  </div>
</template>