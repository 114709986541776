<script>
import { computed, ref, watchEffect } from 'vue';
import { useListingStore } from '~/stores/account/listings.store';

export default {
  props: {
    feature: {
      type: Object,
      required: true
    },
    multi: {
      type: Boolean,
      default: true
    }
  },  

  async setup(props) {
    const listingStore = useListingStore();

    const feature = props.feature;
    const isModalOpen = ref(false);
    const selectedOptions = ref([]);

    // Watch effect to update the store whenever selectedOptions changes
    watchEffect(() => {
      listingStore.updateFeature(feature.feature_id, selectedOptions.value);
    });

    const state = computed(() => listingStore.selectedFromGetter()?.features);

    const updateSelectedOptions = (id) => {
      if (props.multi) {
        const isExist = selectedOptions.value.includes(id);
        if (isExist) {
          selectedOptions.value = selectedOptions.value.filter(opt => opt != id);
        } else {
          selectedOptions.value.push(id);
        }
      } else {
        selectedOptions.value = [id];
      }
    };

    const isOptionChecked = (id) => {
      return selectedOptions.value.includes(id);
    };

    const selectedOptionsDisplay = computed(() => {
      return selectedOptions.value
        .map(option => feature.data_source.find(s => s.id === option)?.name)
        .join(', ') || 'Select';
    });

    return { selectedOptions, updateSelectedOptions, isOptionChecked, feature, state, isModalOpen, selectedOptionsDisplay };
  },
};
</script>

<template>
  <div class="relative w-full">
    <div>
      <h3 class="text-lg font-medium text-indigo-900 leading-normal text-left">
        {{ feature.feature_name }} 
        <span v-if="feature.is_required">*</span>
      </h3>
      <div class="mt-2 flex items-center gap-0">
        <div class="bg-white w-full p-3 rounded-lg flex justify-between items-center" @click="isModalOpen = true">
          <p class="text-sm text-gray-600 truncate">{{ selectedOptionsDisplay }}</p>
          <i class="fa-solid fa-chevron-down"></i>
        </div>

        <w-slideover v-model="isModalOpen" :isBottom="true">
          <div class="sort p-4 overflow-y-auto relative">
            <div class="felx flex-row justify-between align-middle">
              <h1 class="mb-3 text-2xl">{{ feature.feature_name }}</h1>
              <button class="absolute top-5 right-5" @click="isModalOpen = false">
                <p class="text-blue-900">Done</p>
              </button>
            </div>
            <div class="mt-6 space-y-3">
              <div 
                v-for="source in feature.data_source" 
                :key="source.id"
                class="flex items-center border-b pb-2 hover:text-blue-200 rounded-lg p-2"
                @click="updateSelectedOptions(source.id)"
                :class="isOptionChecked(source.id) ? 'bg-blue-600 text-white' : ''"
              >
                <p>{{ source.name }}</p>
              </div>
            </div>
          </div>
        </w-slideover>
      </div>
    </div>
  </div>
</template>
