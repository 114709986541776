<script setup>
import { computed, ref } from 'vue';
import { useListingStore } from '~/stores/account/listings.store';

const listingsStore = useListingStore();
const validationErrors = computed(() => listingsStore.errorsGetter())

const form = computed(() => listingsStore.formGetter())
</script>

<template>
    <div v-for="feature in form.features" :key="feature.id" class="">
        <div class="w-full flex flex-col justify-center gap-2 my-3 border-b pb-3">
            <template v-if="(feature.input_type == 'select' && feature.data_source?.length) && feature.listing_field" >
                <AccountListingsRadioInput :feature="feature"></AccountListingsRadioInput>
            </template>
            <template v-else-if="(['select_multiple'].includes(feature.input_type) || !feature.listing_field) && feature.data_source?.length" >
                <AccountListingsSelectInput 
                    :feature="feature" 
                    :multi="feature.input_type == 'select_multiple'"
                ></AccountListingsSelectInput>
            </template>
            <template v-else-if="['text', 'url', 'textarea', 'number'].includes(feature.input_type)" >
                <AccountListingsTextInput :feature="feature"></AccountListingsTextInput>
            </template>
            <template v-else-if="['file'].includes(feature.input_type)" >
                <AccountListingsFileInput :feature="feature"></AccountListingsFileInput>
            </template>
            <template v-else-if="feature.listing_field == 'city_id'">
                <AccountListingsCityInput :feature="feature"></AccountListingsCityInput>
            </template>
            <template v-for="(error, key) in validationErrors">
                <div class="text-red-500 text-xs" v-if="feature.listing_field == key.split('.')[0] || feature.feature_id == key.split('.')[1]">*{{error[0]}}</div>
            </template>
        </div>
    </div>
</template>
