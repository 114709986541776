<script>
import { computed } from 'vue';
import { useListingStore } from '~/stores/account/listings.store';
import helper from '~/resources/js/libraries/helper';
import { useUploadMediaStore } from '~/stores/account/upload-media.store';

export default {
    props: {
        feature: {
            type: Object,
            required: true
        }
    },  

    async setup(props) {
        const listingStore = useListingStore();
        const uploadMediaStore = useUploadMediaStore();
        const config = useRuntimeConfig();
        const mediaUrl = config.public.mediaUrl
        
        const uploadedImages = ref([]);

        const feature = props.feature

        const loading = ref({
            base64: '',
            name: '',
            progress: 0,
        });

        const errMsg = ref('')

        const handleFileChange = (event) => {
            const file = event.target.files[0];
            if (file) {
                saveFile(file);
            }
        };

        const saveFile = async (file) => {

            let base64 = await fileToBase64(file);

            loading.value = {
                name: Math.random().toString(16).slice(2),
                base64: base64,
                progress: 0
            }

            const base64Resized = await helper.resizeImage(loading.value.base64);

            const formData = { "image": base64Resized, };

            let data = JSON.stringify(formData);
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                headers: {
                    'Content-Type': 'application/json',
                    'validate-height': 500,
                    'validate-width': 500,
                },
                data: data,
                onUploadProgress: (progressEvent) => {
                    let progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    loading.value.progress = progress;
                },
            };

            await uploadMediaStore.uploadMedia(config)
                .then(res => {
                    uploadedImages.value.push(res.data.file?.toString());
                })
                .catch(err => {
                    errMsg.value = err?.response?.data?.message || 'Unexpected error occurred. Please try again.';
                })
                .finally(() => {
                    loading.value.progress = 0;
                })
                
        };

        const removeImage = (image) => {
            uploadedImages.value = uploadedImages.value.filter(i => i != image)
        }

        const fileToBase64 = async (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        };

        watch(
            () => uploadedImages.value?.length, 
            () => listingStore.updateSelectedMedia(uploadedImages.value)
        );

        const state = computed(() => listingStore.selectedFromGetter())

        return { handleFileChange, removeImage, feature, state, uploadedImages, mediaUrl };
    },
};
</script>
<template>
    <div class="relative w-full">
        <div class="border-b">
            <h3 class="text-lg font-medium text-indigo-900 leading-normal text-left">
                {{ feature.feature_name }} 
                <span v-if="feature.is_required">*</span>
            </h3>
            <div 
                class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 bg-white"
            >
                <div class="text-center">
                    <svg class="mx-auto h-12 w-12 text-gray-300" viewBox="0 0 24 24" fill="currentColor"
                        aria-hidden="true">
                        <path fill-rule="evenodd"
                            d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                            clip-rule="evenodd" />
                    </svg>
                    <div class="mt-4 flex text-sm leading-6 text-gray-600">
                        <label for="file-upload"
                            class="relative cursor-pointer rounded-md font-semibold text-indigo-600 hover:text-indigo-500">
                            <span>Upload a file</span>
                            <input 
                                id="file-upload" 
                                name="file-upload" 
                                type="file" 
                                class="sr-only"
                                @change="handleFileChange"
                            >
                        </label>
                        <p class="pl-1">or drag and drop</p>
                    </div>
                    <p class="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                </div>
            </div>
            <div 
                v-if="uploadedImages?.length"
                class="flex snap-x my-5"
            >
                <div 
                    v-for="(image, i) in uploadedImages"
                    :key="i"
                    @click.stop="removeImage(image)"
                    class="relative w-24"
                >
                    <span class="absolute text-xl -top-2 right-0 text-red-500 font-bold bg-white flex justify-center h-8 w-8 leading-8 text-center rounded-full shadow-lg"> X </span>
                    <img 
                        @click.stop="openFileDialog"
                        :src="mediaUrl + '/' + image + '?w=300&h=300&fit=contain'" 
                        alt="image"
                        class="h-24 w-24 rounded-lg object-cover" 
                    />
                </div>
            </div>
        </div>
    </div>
</template>